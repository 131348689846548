import { defineMessage } from 'react-intl';

export const messages = {
  planetTabVideo: defineMessage({
    id: 'store.planetTabs.video',
    defaultMessage: 'ビデオ',
  }),
  planetTabAmateur: defineMessage({
    id: 'store.planetTabs.amateur',
    defaultMessage: '素人',
  }),
  planetTabAnime: defineMessage({
    id: 'store.planetTabs.anime',
    defaultMessage: 'アニメ',
  }),
  planetTabVr: defineMessage({
    id: 'store.planetTabs.vr',
    defaultMessage: 'VR',
  }),
};
