import { KafkaContext, KafkaPropsWithTarget } from '@/domain/log/KafkaContext';
import type { StoreSaleBannerLog } from '@/domain/log/__types__/store--saleBanner';
import BeemiLink, { BeemiLinkProps } from '@/shared/components/BeemiLink';
import UserAgentContext from '@/shared/UserAgentContext';
import { getTailwindConfigScreens } from '@/shared/utils/getTailwindConfig';
import { mergeQueryString } from '@/shared/utils/routeHelper';
import classNames from 'classnames';
import { useCallback, useContext } from 'react';

type Size = 'FULL' | 'BLOCK';

export type BannerLog = StoreSaleBannerLog;

export interface BannerProps {
  className?: string;
  imgSrcMobile: string;
  imgSrcDesktop: string;
  imgAlt: string;
  href: BeemiLinkProps['href'];
  onClick?: () => void;
  size: Size;
  kafkaProps?: KafkaPropsWithTarget<BannerLog>;
}

const SIZE_MAPS: Record<Size, string> = {
  FULL: 'w-full',
  BLOCK: 'w-75 h-[168px] md:w-[480px] md:h-[270px]',
};

/**
 * this function is 95% similar to BeemiImage#customLoader()
 * can consider to refactor this for anyone keen
 */
function mergeAkamaiParams({
  src,
  isWebpSupported,
}: {
  src: string;
  isWebpSupported: boolean;
}): string {
  const [pathname, queryParams] = src.split('?');
  const urlSearchParams = new URLSearchParams(queryParams);
  const query = Object.fromEntries(urlSearchParams.entries());

  const { path } = mergeQueryString(
    {
      pathname: pathname,
      asPath: pathname,
      query,
    },
    {
      'output-format': isWebpSupported ? 'webp' : 'jpg',
      'output-quality': '70',
    }
  );

  return path;
}

const Banner: React.FC<BannerProps> = ({
  className,
  imgSrcMobile,
  imgSrcDesktop,
  imgAlt,
  href,
  size = 'FULL',
  kafkaProps,
}) => {
  const { isWebpSupported } = useContext(UserAgentContext);
  const { client } = useContext(KafkaContext);

  const handleClick = useCallback(() => {
    if (kafkaProps) {
      const { target, ...rest } = kafkaProps;
      client?.trackUser<BannerLog>(
        target as BannerLog['event']['target'],
        'click',
        'user_click_dimension_1_default',
        {
          ...rest,
        }
      );
    }
  }, [client, kafkaProps]);

  return (
    <article className={classNames(className, SIZE_MAPS[size])}>
      <BeemiLink href={href} onClick={handleClick} data-testid="Banner">
        <h3>
          <picture>
            <source
              srcSet={mergeAkamaiParams({
                src: imgSrcDesktop,
                isWebpSupported,
              })}
              media={`(min-width: ${getTailwindConfigScreens().md ?? '768px'})`}
            />
            <img
              className={classNames(
                'border border-purple/10 rounded-md shadow-sm object-cover',
                SIZE_MAPS[size]
              )}
              src={mergeAkamaiParams({ src: imgSrcMobile, isWebpSupported })}
              alt={imgAlt}
            />
          </picture>
        </h3>
      </BeemiLink>
    </article>
  );
};

export default Banner;
