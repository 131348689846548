import {
  KafkaContext,
  KafkaPropsWithTargetPrefix,
} from '@/domain/log/KafkaContext';
import { LibraryChapterMyListShowAllLog } from '@/domain/log/__types__/library-chapterMyList-showAll';
import { LibraryPackageMyListShowAllLog } from '@/domain/log/__types__/library-packageMyList-showAll';
import { LibraryPurchasedShowAllLog } from '@/domain/log/__types__/library-purchased-showAll';
import { StoreNewListShowAllLog } from '@/domain/log/__types__/store-newList-showAll';
import { StoreRankingListShowAllLog } from '@/domain/log/__types__/store-rankingList-showAll';
import { StoreSaleListShowAllLog } from '@/domain/log/__types__/store-saleList-showAll';
import { StoreTitleListShowAllLog } from '@/domain/log/__types__/store-titleList-showAll';
import PackageCard, {
  PackageCardProps,
} from '@/shared/components//Card/PackageCard';
import Banner, { BannerProps } from '@/shared/components/Banner';
import ShowMoreButton, {
  ShowMoreButtonProps,
} from '@/shared/components/Button/ShowMoreButton';
import ChapterCard, {
  ChapterCardProps,
} from '@/shared/components/Card/ChapterCard';
import Slider from '@/shared/components/Slider';
import { FC, useCallback, useContext } from 'react';

type PackageCardBlockType = 'PACKAGE_SMALL' | 'PACKAGE_LARGE';
type ChapterCardBlockType = 'CHAPTER_SMALL';
type SaleBlockType = 'SALE';
type BlockType = PackageCardBlockType | ChapterCardBlockType | SaleBlockType;

type ShowAllDimensionOneLog =
  | StoreSaleListShowAllLog
  | StoreRankingListShowAllLog
  | StoreNewListShowAllLog
  | StoreTitleListShowAllLog;

type ShowAllDimensionZeroLog =
  | LibraryPackageMyListShowAllLog
  | LibraryChapterMyListShowAllLog
  | LibraryPurchasedShowAllLog;

type ShowAllLog = ShowAllDimensionOneLog | ShowAllDimensionZeroLog;
interface BlockPropsBase {
  className?: string;
  type: BlockType;
  title: string;
  showMoreLink: ShowMoreButtonProps['href'];
  kafkaProps?: KafkaPropsWithTargetPrefix<ShowAllLog>;
  'data-testid'?: string;
}

export interface PackageCardBlockProps extends BlockPropsBase {
  type: PackageCardBlockType;
  cards?: PackageCardProps[];
}

interface ChapterCardBlockProps extends BlockPropsBase {
  type: ChapterCardBlockType;
  cards?: ChapterCardProps[];
}

export interface SaleBlockProps extends BlockPropsBase {
  type: SaleBlockType;
  cards?: Array<
    Omit<BannerProps, 'size'> & {
      id: string;
    }
  >;
}

export type BlockProps =
  | PackageCardBlockProps
  | ChapterCardBlockProps
  | SaleBlockProps;

const CARD_SIZE_MAP: Record<
  PackageCardBlockType | ChapterCardBlockType,
  'BLOCK_LARGE' | 'BLOCK_SMALL'
> = {
  PACKAGE_LARGE: 'BLOCK_LARGE',
  PACKAGE_SMALL: 'BLOCK_SMALL',
  CHAPTER_SMALL: 'BLOCK_SMALL',
};

const SLIDE_ARROW_CLASSNAME_MAP: Record<BlockType, string> = {
  PACKAGE_LARGE: 'mt-19.5',
  PACKAGE_SMALL: 'mt-12.5',
  CHAPTER_SMALL: 'mt-5',
  SALE: 'mt-30',
};

type EmptyPlaceholderProps = {
  messageLine1?: string;
  messageLine2?: string;
  messageLine3?: string;
  messageLine4?: string;
};

const EmptyPlaceholder = ({
  messageLine1,
  messageLine2,
  messageLine3,
  messageLine4,
}: EmptyPlaceholderProps) => {
  return (
    <div className="inline-flex w-full px-5 gap-x-3 md:px-12">
      <div className="flex flex-col items-center justify-center w-full bg-purple/5 h-[200px] gap-2 md:gap-3">
        <div className="flex flex-col items-center justify-center text-caption md:flex-row text-purple/80">
          <span>{messageLine1}</span>
          <span>{messageLine2}</span>
        </div>
        <div className="flex flex-col items-center justify-center text-label text-purple/30 md:flex-row">
          <span>{messageLine3}</span>
          <span>{messageLine4}</span>
        </div>
      </div>
    </div>
  );
};

const Block: FC<BlockProps & EmptyPlaceholderProps> = (props) => {
  const {
    showMoreLink,
    title,
    className,
    messageLine1,
    messageLine2,
    messageLine3,
    messageLine4,
    kafkaProps,
  } = props;

  const { client } = useContext(KafkaContext);

  const hasCard = props.cards && props.cards.length > 0;

  const isPackage =
    props.type === 'PACKAGE_SMALL' || props.type === 'PACKAGE_LARGE';
  const isChapter = props.type === 'CHAPTER_SMALL';

  const handleClickShowMore = useCallback(() => {
    if (kafkaProps) {
      const {
        targetPrefix,
        base_schema = 'user_click_dimension_1_default',
        ...rest
      } = kafkaProps;
      const target = `${targetPrefix}-showAll` as ShowAllLog['event']['target'];

      client?.trackUser<ShowAllLog>(target, 'click', base_schema, rest);
    }
  }, [client, kafkaProps]);

  return (
    <section
      data-testid={props['data-testid'] || `section-block-${title}`}
      className={className}
    >
      <div className="flex justify-between px-5 mb-3 md:px-12 md:justify-start">
        <h2 className="mr-4 font-semibold text-md">{title}</h2>
        {hasCard && (
          <ShowMoreButton href={showMoreLink} onClick={handleClickShowMore} />
        )}
      </div>

      <Slider arrowButtonClassName={SLIDE_ARROW_CLASSNAME_MAP[props.type]}>
        <section>
          {hasCard ? (
            <div className="inline-flex px-5 gap-x-3 md:px-12">
              {isPackage &&
                props.cards?.map((card, index) => (
                  <PackageCard
                    {...card}
                    key={`${index}_${card.id}`}
                    cardSize={CARD_SIZE_MAP[props.type]}
                  />
                ))}
              {isChapter &&
                props.cards?.map((card, index) => (
                  <ChapterCard
                    {...card}
                    key={`${index}_${card.id}`}
                    cardSize={CARD_SIZE_MAP[props.type]}
                  />
                ))}
              {props.type === 'SALE' &&
                props.cards?.map((card, index) => (
                  <Banner {...card} size="BLOCK" key={`${index}_${card.id}`} />
                ))}
            </div>
          ) : (
            <EmptyPlaceholder
              messageLine1={messageLine1}
              messageLine2={messageLine2}
              messageLine3={messageLine3}
              messageLine4={messageLine4}
            />
          )}
        </section>
      </Slider>
    </section>
  );
};

export default Block;
