import classNames from 'classnames';
import React, {
  AnchorHTMLAttributes,
  ButtonHTMLAttributes,
  HTMLAttributes,
} from 'react';

type Sizes = 'DEFAULT' | 'FULL';

const SIZES_MAP: Record<Sizes, string> = {
  DEFAULT: 'md:w-20',
  FULL: 'w-full',
};

type Variants = 'LEFT' | 'CENTER' | 'RIGHT' | 'AUTO';

const VARIANTS_MAP: Record<Variants, string> = {
  LEFT: 'rounded-l-md',
  CENTER: '',
  RIGHT: 'rounded-r-md',
  AUTO: 'first:rounded-l-md last:rounded-r-md',
};

type HTMLType = ButtonHTMLAttributes<HTMLButtonElement> &
  AnchorHTMLAttributes<HTMLAnchorElement> &
  HTMLAttributes<HTMLDivElement>;

export interface TabProps extends HTMLType {
  size?: Sizes;
  variant?: Variants;
  isActive?: boolean;
  component?: 'button' | 'a' | 'div';
}

const Tab = React.forwardRef<null, TabProps>(
  (
    {
      size = 'DEFAULT',
      variant = 'AUTO',
      isActive,
      // Multiple tabs need to be rendered together so we can't wrap it on <a> tag
      // We need to make the tab itself an <a> tag if needed.
      component = 'button',
      children,
      ...props
    },
    ref
  ) => {
    const Component = component;
    return (
      <Component
        className={classNames(
          'inline-flex items-center justify-center h-7 text-sm border-2 border-r-0 last:border-r-2 border-purple transition-colors',
          SIZES_MAP[size],
          VARIANTS_MAP[variant],
          isActive ? 'bg-purple text-white font-semibold' : 'hover:bg-purple/30'
        )}
        ref={ref}
        {...props}
      >
        {children}
      </Component>
    );
  }
);
Tab.displayName = 'Tab';

export default Tab;
