import useTailwindBreakpoint from '@/shared/hooks/useTailwindBreakpoint';
import classNames from 'classnames';
import React, {
  ButtonHTMLAttributes,
  ReactNode,
  useEffect,
  useRef,
  useState,
} from 'react';
import Icon from '../Icon';

type ArrowButtonProps = ButtonHTMLAttributes<HTMLButtonElement>;

const ArrowButton: React.FC<ArrowButtonProps> = ({
  children,
  className,
  ...rest
}) => {
  return (
    <button
      {...rest}
      className={classNames(
        'h-24 w-12 bg-white/80 hover:bg-white transition-colors backdrop-filter	backdrop-blur-lg',
        className
      )}
    >
      {children}
    </button>
  );
};

interface SliderProps {
  arrowButtonClassName?: string;
  children?: ReactNode;
}

const Slider: React.FC<SliderProps> = ({ arrowButtonClassName, children }) => {
  const containerDiv = useRef<HTMLDivElement>(null);
  const scrollDiv = useRef<HTMLDivElement>(null);

  // Used to decide the maxinum scroll offset when user clicks left/right arrow button
  const [containerWidth, setContainerWidth] = useState(0);
  const scrollOffset = containerWidth * 0.75;

  const [showLeftButton, setShowLeftButton] = useState(false);
  const [showRightButton, setShowRightButton] = useState(true);

  // We are using css to overwrite the default scrollbar here, because we need to hide the
  // scroll bar (e.g. on windows desktop) when it's not needed.
  const [isScrolling, setIsScrolling] = useState(false);
  const scrollEndTimeout = useRef<NodeJS.Timeout | null>(null);
  const isMd = useTailwindBreakpoint('md');
  const shouldHideScrollBar = isMd && !isScrolling;

  // Initially hide right button if the content is not longer enough to become scrollable
  useEffect(() => {
    function updateArrowButtonDisplay() {
      if (scrollDiv.current) {
        if (
          scrollDiv.current.scrollLeft + scrollDiv.current.clientWidth ===
          scrollDiv.current.scrollWidth
        ) {
          setShowRightButton(false);
        } else {
          setShowRightButton(true);
        }
      }
    }

    updateArrowButtonDisplay();

    window.addEventListener('resize', updateArrowButtonDisplay);
    return () => window.removeEventListener('resize', updateArrowButtonDisplay);
  }, []);

  useEffect(() => {
    setContainerWidth(containerDiv.current?.clientWidth || 0);

    function updateContainerWidth() {
      setContainerWidth(containerDiv.current?.clientWidth || 0);
    }

    window.addEventListener('resize', updateContainerWidth);

    return () => window.removeEventListener('resize', updateContainerWidth);
  }, []);

  return (
    <div className="relative flex" ref={containerDiv}>
      <ArrowButton
        className={classNames(
          'absolute z-10 left-0 rounded-r-md invisible opacity-0 transition-all',
          showLeftButton ? 'md:visible md:opacity-100' : '',
          arrowButtonClassName
        )}
        onClick={() =>
          scrollDiv.current?.scrollTo({
            left: scrollDiv.current.scrollLeft - scrollOffset,
            behavior: 'smooth',
          })
        }
      >
        <div className="w-12 h-12 text-purple/30">
          <Icon name="HALF_ARROW_LEFT" size="FULL" />
        </div>
      </ArrowButton>
      <div
        className={classNames(
          'w-full overflow-x-auto box-border self-start',
          // We need to add this magical css for scaled thumbnail hover effect and box shadow.
          // Part of the scaled thumbnail/shadow effect will be out of range of this Slider,
          // and it can't display that part due to overflow: auto, so we need to add extra "hidden"
          // top/bottom padding to make sure that hover/shadow effect works
          '-mt-4 pt-4 -mb-4 pb-4',
          shouldHideScrollBar
            ? 'slider-scrollbar-hidden'
            : 'slider-scrollbar-visible'
        )}
        ref={scrollDiv}
        onScroll={() => {
          if (scrollDiv.current) {
            // scrolled to left end
            if (scrollDiv.current.scrollLeft === 0) {
              if (showLeftButton) setShowLeftButton(false);
            } else {
              if (!showLeftButton) setShowLeftButton(true);
            }

            // scrolled to right end
            if (
              scrollDiv.current.scrollLeft + scrollDiv.current.clientWidth ===
              scrollDiv.current.scrollWidth
            ) {
              if (showRightButton) setShowRightButton(false);
            } else {
              if (!showRightButton) setShowRightButton(true);
            }
          }

          if (!isScrolling) setIsScrolling(true);

          if (scrollEndTimeout.current) {
            clearTimeout(scrollEndTimeout.current);
          }
          scrollEndTimeout.current = setTimeout(() => {
            if (isScrolling) setIsScrolling(false);
          }, 300);
        }}
      >
        {children}
      </div>
      <ArrowButton
        className={classNames(
          'absolute z-10 right-0 rounded-l-md invisible opacity-0 transition-all',
          showRightButton ? 'md:visible md:opacity-100' : '',
          arrowButtonClassName
        )}
        onClick={() =>
          scrollDiv.current?.scrollTo({
            left: scrollDiv.current.scrollLeft + scrollOffset,
            behavior: 'smooth',
          })
        }
      >
        <div className="w-12 h-12 text-purple/30">
          <Icon name="HALF_ARROW_RIGHT" size="FULL" />
        </div>
      </ArrowButton>
    </div>
  );
};

export default Slider;
