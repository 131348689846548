import Icon from '@/shared/components/Icon';
import appLocalStorage from '@/shared/utils/appLocalStorage';
import React, { ReactNode, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';

interface StaticSvodHintProps {
  isOpen: boolean;
  onClick?: () => void;
}
export const StaticSvodHint: React.FC<StaticSvodHintProps> = ({
  isOpen,
  onClick = () => null,
}) => {
  const intl = useIntl();
  return isOpen ? (
    <div className="relative px-10 py-6 text-sm leading-8 rounded-md bg-purple text-white/80 md:text-center md:text-md">
      <button
        className="absolute left-1.5 top-1.5 text-white/60 w-5 h-5 md:w-8 md:h-8 md:left-2 md:top-2 hover:text-white transition"
        onClick={onClick}
      >
        <Icon name="CLOSE_ICON" size="FULL" />
      </button>

      {intl.formatMessage(
        {
          id: 'svodHint',
          defaultMessage:
            '<withIcon>見放題対象</withIcon>の作品は月額プランに加入することで<bold>見放題で視聴できます</bold>',
        },
        {
          withIcon: (str: ReactNode) => (
            <span className="inline-flex items-center px-2 mr-1 -ml-1 text-white border-2 rounded-full border-white/40">
              {str}
              <div className="flex text-pink w-4.5 h-4.5">
                <Icon name="ADDED_ICON" size="FULL" />
              </div>
            </span>
          ),
          // eslint-disable-next-line react/display-name
          bold: (str) => <b className="text-white">{str}</b>,
        }
      )}
    </div>
  ) : null;
};
StaticSvodHint.displayName = 'StaticSvodHint';

// TODO WF-10590: When user logouts, the cache should be cleared.
const SvodHint: React.FC = () => {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    const svodHintDismissTime = appLocalStorage.getString('svodHintDismiss');
    const isDismissed = !!svodHintDismissTime;
    if (isDismissed) {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [setIsOpen]);

  const onClick = () => {
    setIsOpen(false);
    appLocalStorage.set('svodHintDismiss', `${Date.now()}`);
  };

  return <StaticSvodHint isOpen={isOpen} onClick={onClick} />;
};

export default SvodHint;
